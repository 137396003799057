import React, { useState, useEffect } from 'react'
import { Avatar } from 'baseui/avatar'
import { Display4 } from 'baseui/typography'
import { useStyletron } from 'baseui'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal'
import { Checkbox } from 'baseui/checkbox'
import { Button, KIND, SHAPE, SIZE } from 'baseui/button'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Notification } from 'baseui/notification'
import Emoji from './Emoji'
// import { FiExternalLink } from 'react-icons/fi'
import whatsAppLogo from '../images/WhatsApp.svg'
// import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from 'baseui/popover'
// import { FiInstagram } from 'react-icons/fi'

const SuccessModal = ({ waLink, isOpen, setIsOpen, qrDataUrl }) => {
  const [css] = useStyletron()
  const [copied, setCopied] = useState(false)
  // const [dmCopied, setDMCopied] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const [downloadString, setDownloadString] = useState(qrDataUrl)
  const [addTextToQR, setAddTextToQR] = useState(false)

  const goToPremium = () => {
    window.plausible('Modal To App')
    window.open('https://app.wa.link/register', '_blank')
  }

  const checkCopiedAndClose = () => {
    if (copied) {
      setCopied(false)
      setIsOpen(false)
      setShowNotification(false)
    } else {
      setShowNotification(true)
    }
  }

  useEffect(() => {
    if (addTextToQR) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = 300
      canvas.height = 320

      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, 300, 320)
      const image = new Image()
      image.src = qrDataUrl
      image.onload = function() {
        ctx.drawImage(image, 0, 0)
        ctx.font = 'bold 20px Helvetica'
        ctx.fillStyle = 'black'
        ctx.fillText(waLink.replace('https://', ''), 160, 305)
        setDownloadString(canvas.toDataURL('image/png'))
      }
    } else {
      setDownloadString(qrDataUrl)
    }
  }, [addTextToQR, waLink, qrDataUrl])

  return (
    <Modal
      isOpen={isOpen}
      onClose={checkCopiedAndClose}
      unstable_ModalBackdropScroll={true}
      overrides={{
        Dialog: {
          style: {
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            borderBottomLeftRadius: '10px',
          },
        },
      }}
    >
      <ModalHeader>Este es tu link corto de WhatsApp</ModalHeader>
      <ModalBody>
        <p>
          Copia y compárte tu link donde quieras que te contacten fácilmente.
          {/* (Usa{' '}
          <StatefulPopover
            showArrow
            content={
              <>
                <Paragraph2 padding="scale300">
                  Actualmente,{' '}
                  <FiInstagram style={{ verticalAlign: 'middle' }} /> Instagram
                  presenta un error al enviar mensajes con links que
                  redireccionen a cualquier plataforma de Meta. Usa esta versión
                  de tu link si vas a enviarlo en mensajes de Instagram DM.
                  <br />
                  <CopyToClipboard
                    text={'https://' + waLink.replace('https://', 'dm.')}
                    onCopy={() => setDMCopied(true)}
                  >
                    <a href="/" onClick={e => e.preventDefault()}>
                      {dmCopied ? '¡Copiado!' : 'Copiar link para Instagram DM'}
                    </a>
                  </CopyToClipboard>
                </Paragraph2>
              </>
            }
            accessibilityType={'tooltip'}
            triggerType={TRIGGER_TYPE.hover}
            placement={PLACEMENT.bottom}
            overrides={{
              Body: {
                style: {
                  width: '300px',
                  marginTop: '10px',
                  borderTopLeftRadius: '15px',
                  borderBottomLeftRadius: '15px',
                  borderTopRightRadius: '15px',
                  borderBottomRightRadius: '15px',
                },
              },
              Inner: {
                style: {
                  borderTopLeftRadius: '15px',
                  borderBottomLeftRadius: '15px',
                  borderTopRightRadius: '15px',
                  borderBottomRightRadius: '15px',
                },
              },
            }}
          >
            <strong style={{ textDecoration: 'underline' }}>
              {waLink.replace('https://', 'dm.')}
            </strong>
          </StatefulPopover>{' '}
          para enviar el link por mensajes de Instagram) */}
        </p>
        <div
          className={css({
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
          })}
        >
          <Avatar name="WA Logo" size="scale1200" src={whatsAppLogo} />
          <a
            href={waLink}
            target="_blank"
            rel="noopener noreferrer"
            className={css({
              marginLeft: '5px',
              wordWrap: 'break-word',
              textDecoration: 'none',
            })}
          >
            <Display4>{waLink.replace('https://', '')}</Display4>
          </a>
        </div>
        <div
          className={css({
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '10px',
            cursor: 'pointer',
          })}
          onClick={goToPremium}
        >
          <div
            className={css({
              color: 'rgb(54, 135, 89)',
              // color: '#fff',
              backgroundColor: ' rgb(240, 249, 244)',
              // backgroundColor: '#1fd1f9',
              // backgroundImage:
              //   'linear-gradient(315deg, #1fd1f9 0%, #b621fe 74%)',
              // 'linear-gradient(-45deg, #23a6d5, #23d5ab)',
              width: '100%',
              borderRadius: '10px',
              padding: '1rem',
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 4px',
              // boxShadow: 'rgba(0,0,0, 0.8) 0px 1px 5px',
              border: '1px solid rgb(54, 135, 89)',
              textAlign: 'center',
              transition: '0.2s',
              ':hover': {
                // boxShadow: 'rgba(0,0,0, 0.8) 0px 1px 5px',
                // boxShadow: 'rgba(0,0,0, 0.8) 0px 2px 5px',
                boxShadow: 'rgba(0,0,0, 0.4) 0px 2px 5px',
              },
            })}
          >
            <p style={{ fontSize: '15px' }}>
              Adquiere un <Emoji symbol={'👑'} />{' '}
              <a
                // href="https://crear.wa.link/premium"
                href="https://app.wa.link/register"
                target="_blank"
                rel="noopener noreferrer"
                alt="Plan premium"
                onClick={e => e.preventDefault()}
                // style={{ color: '#fff' }}
              >
                Plan Premium
              </a>{' '}
              desde <strong>USD $6/año</strong> para crear wa.link(s) con:
              {/* como <strong>wa.link/TuMarca</strong> con: */}
              {/* con información editable,
              analítica de clics, buscador, Multilink, Multiagente y mucho
              más... */}
              {/* Crea tu propio <strong>wa.link/TuMarca</strong> con un{' '}
              <Emoji symbol={'👑'} /> */}
              {/* <OutboundLink
                // href="https://crear.wa.link/premium"
                href="https://app.wa.link/register"
                target="_blank"
                rel="noopener noreferrer"
                alt="Plan premium"
                onClick={e => e.preventDefault()}
                style={{ color: '#fff' }}
              >
                Plan Premium
              </OutboundLink> */}
              {/* <Emoji symbol={'👑'} /> y podrás ver la analítica de los clics y
              editar la información del link en cualquier momento. */}
            </p>

            <ul
              className="checkList"
              // style={{ textAlign: 'left', listStyleType: 'none' }}
            >
              <li>
                URL personalizada <strong>(wa.link/TuMarca)</strong>
              </li>
              <li>
                <strong>Edita</strong> el teléfono, mensaje y URL.
              </li>
              <li>
                <strong>Analítica</strong> de clics por hora, días o mes con su
                fuente, sistema operativo y país
              </li>
              <li>
                Acceso a Página multilink, <strong>servicio Multiagente</strong>
                , buscador de links y mucho más...
              </li>
            </ul>
            <Button
              shape={SHAPE.pill}
              size={SIZE.compact}
              // endEnhancer={() => <FiExternalLink size={16} />}
              overrides={{
                BaseButton: {
                  style: {
                    marginTop: '1rem',
                    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
                  },
                },
              }}
            >
              <Emoji symbol={'👑'} /> Empezar con Premium
            </Button>
          </div>
        </div>

        <div
          className={css({
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '10px',
          })}
        >
          {!copied && showNotification && (
            <Notification
              overrides={{
                Body: {
                  style: {
                    borderTopRightRadius: '10px',
                    borderTopLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                    borderBottomLeftRadius: '10px',
                  },
                },
              }}
            >
              Asegúrate de copiar el link primero.
            </Notification>
          )}
        </div>
        <div
          className={css({
            display: 'flex',
            justifyContent: 'space-evenly',
          })}
        >
          <img
            src={downloadString}
            alt="generated-qr"
            style={{ width: 300, height: addTextToQR ? 320 : 300 }}
          />
        </div>
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          })}
        >
          <Checkbox
            checked={addTextToQR}
            onChange={e => setAddTextToQR(e.target.checked)}
          >
            Agregar link al QR
          </Checkbox>
        </div>
      </ModalBody>
      <ModalFooter>
        <CopyToClipboard text={waLink} onCopy={() => setCopied(true)}>
          <ModalButton
            shape={SHAPE.pill}
            size={SIZE.compact}
            overrides={{
              BaseButton: {
                style: {
                  backgroundColor: '#00b66c',
                  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
                },
              },
            }}
          >
            {copied ? '¡Copiado!' : 'Copiar link'}
          </ModalButton>
        </CopyToClipboard>
        <a
          download={waLink.replace('https://', '') + '.png'}
          href={downloadString}
          style={{ textDecoration: 'none' }}
        >
          <ModalButton
            kind={KIND.secondary}
            shape={SHAPE.pill}
            size={SIZE.compact}
            overrides={{
              BaseButton: {
                style: {
                  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
                },
              },
            }}
          >
            Descargar QR
          </ModalButton>
        </a>
      </ModalFooter>
    </Modal>
  )
}

export default SuccessModal
